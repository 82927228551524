import { FC } from 'react'
import { isCtaBlock, isImageBlock, isTextBlock } from 'lib/utils/typesGuards'

import { CtaBlock } from './components/CtaBlock/CtaBlock'
import { ImageBlock } from './components/ImageBlock/ImageBlock'
import { TextBlock } from './components/TextBlock/TextBlock'

export type TBlock = Data.PostBlock<Data.PostBlockType>

type TProps = {
  block: TBlock
  index: number
}

const ContentBlock: FC<TProps> = ({ block, index }) => {
  if (isTextBlock(block)) {
    return <TextBlock block={block} index={index} />
  }

  if (isImageBlock(block)) {
    return <ImageBlock block={block} />
  }

  if (isCtaBlock(block)) {
    return <CtaBlock block={block} />
  }

  return null
}

export { ContentBlock }
