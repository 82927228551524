import { produce } from 'immer'
import { setDataToLS } from 'lib/utils/localStorage'
import { create } from 'zustand'

export type TUserRatings = {
  [postId: number]: number
}

interface IClaimState {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  userRatings: TUserRatings | null
  setUserRatings: (raings: TUserRatings) => void
  addUserRating: (postId: number, value: number) => void
}

export const MAX_STARS = 5
export const RATING_LS_KEY = 'post_ratings'

const useRatingController = create<IClaimState>()((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) => set(() => ({ isOpen })),
  userRatings: null,
  setUserRatings: (userRatings) => set(() => ({ userRatings })),
  addUserRating: (postId, value) =>
    set(
      produce<IClaimState>((state) => {
        if (state.userRatings) {
          state.userRatings[postId] = value
          setDataToLS(RATING_LS_KEY, state.userRatings)
        }
      }),
    ),
}))

export { useRatingController }
