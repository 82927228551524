import { FC, PropsWithChildren } from 'react'
import cn from 'classnames'
import BgDots from 'images/bg_dots.png'

export enum CardType {
  Light = 'Light',
  Dark = 'Dark',
  Blue = 'Blue',
  Black = 'Black',
}

export enum OutlineType {
  Border = 'Border',
  Shadow = 'Shadow',
  None = 'None',
}

type TProps = {
  type?: CardType
  outlineType?: OutlineType
  noBg?: boolean
  className?: string
} & PropsWithChildren

const Card: FC<TProps> = ({
  type = CardType.Light,
  outlineType = OutlineType.None,
  noBg = false,
  className = 'p-8 gap-8',
  children,
}) => {
  return (
    <div
      className={cn(
        `flex flex-col items-center`,
        {
          'border-border-primary bg-surface-white rounded-md': type === CardType.Light,
          'bg-surface-primary bg-cover rounded-md': type === CardType.Dark,
          'bg-surface-quarternary bg-cover rounded-2xl': type === CardType.Blue,
          'bg-surface-black bg-cover rounded-2xl shadow-cardBlack': type === CardType.Black,
          'border-1 border-solid': outlineType === OutlineType.Border,
          'shadow-main': outlineType === OutlineType.Shadow,
        },
        className,
      )}
      style={
        [CardType.Dark, CardType.Blue, CardType.Black].includes(type) && !noBg
          ? {
              backgroundImage: `url(${BgDots})`,
              backgroundPositionX: '50%',
              backgroundPositionY: '50%',
            }
          : {}
      }
    >
      {children}
    </div>
  )
}

export { Card }
