import { FC } from 'react'
import Icon from 'images/svg/decoration.svg'

type TProps = {
  topLeft?: boolean
  topRight?: boolean
  bottomLeft?: boolean
  bottomRight?: boolean
}

const Decoration: FC<TProps> = ({ topLeft = true, topRight = true, bottomLeft = true, bottomRight = true }) => {
  return (
    <div className="pointer-events-none absolute h-full w-full max-lg:hidden">
      {topLeft && (
        <div className="absolute -left-8 -top-8">
          <img src={Icon} className="object-contain" />
        </div>
      )}
      {topRight && (
        <div className="absolute -right-8 -top-8 rotate-90">
          <img src={Icon} className="object-contain" />
        </div>
      )}
      {bottomLeft && (
        <div className="absolute -bottom-8 -left-8 rotate-90">
          <img src={Icon} className="object-contain" />
        </div>
      )}
      {bottomRight && (
        <div className="absolute -bottom-8 -right-8">
          <img src={Icon} className="object-contain" />
        </div>
      )}
    </div>
  )
}

export { Decoration }
