import { FC } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Dialog } from '@blueprintjs/core'
import { useRatingController } from 'client/controllers/ratingController'

import { RatingSelector } from './components/RatingSelector/RatingSelector'

type TProps = {}

const RatingDialog: FC<TProps> = () => {
  const { isOpen, setIsOpen } = useRatingController()

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      backdropClassName="bg-backdrop"
      className="bg-backdrop-content max-sm:w-[calc(100vw-3rem)]"
    >
      <GoogleReCaptchaProvider language="en" reCaptchaKey={import.meta.env.VITE_CAPTCHA_SITE_KEY}>
        <RatingSelector />
      </GoogleReCaptchaProvider>
    </Dialog>
  )
}

export { RatingDialog }
