import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { Link, usePage } from '@inertiajs/react'
import { format } from 'date-fns'
import { POST_DATE_FORMAT_STRING } from 'lib/constants/dates'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {
  nextPosts: Data.PostShort[]
}

const NextPosts: FC<{}> = () => {
  const {
    props: { nextPosts },
  } = usePage<TProps>()
  const { t } = useTranslation(ClientLocaleNS.PostPage)
  const { t: tCommon } = useTranslation(ClientLocaleNS.Common)

  return (
    <div className="flex w-full flex-col gap-8 lg:sticky lg:top-8">
      <Text tagName="span" className="font-primary-medium---15pt uppercase text-text-secondary">
        {t('next_posts_block.title')}
      </Text>
      <ul className="flex w-full flex-col gap-8">
        {nextPosts.map((post) => {
          const url = post.url || ''
          const isSlashed = url[0] === '/'

          return (
            <li key={post.id} className="flex w-full flex-col">
              <Link href={`/magazine${isSlashed ? '' : '/'}${url}`} className="flex focus:outline-none">
                <div className="flex w-full flex-col gap-8">
                  <div className="flex flex-1 flex-col gap-4">
                    <div className="flex w-full flex-1 flex-col gap-4 text-text-white">
                      <Text tagName="span" className="font-primary-medium---15pt">
                        {post.title}
                      </Text>
                      <Text tagName="span" className="font-primary-regular---12pt">
                        {post.description}
                      </Text>
                    </div>
                    <Text tagName="span" className="font-primary-regular---12pt text-text-tertiary">{`${
                      post.reading_time ?? 0
                    } ${tCommon('captions.reading_time')} / ${format(
                      post.updated_at * 1000,
                      POST_DATE_FORMAT_STRING,
                    )}`}</Text>
                  </div>
                  <div className="flex h-[calc(12vw)] w-full items-center overflow-hidden max-lg:h-[calc(45vw)]">
                    {post.image_url ? (
                      <img src={post.image_url} className="flex w-full object-contain" />
                    ) : (
                      <div className="flex flex-1 bg-surface-secondary" />
                    )}
                  </div>
                </div>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export { NextPosts }
