import { FC } from 'react'
import { Text } from '@blueprintjs/core'
import { Link, usePage } from '@inertiajs/react'
import cn from 'classnames'
import { ResponsiveBlock, TResponsiveBlockSize } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ClientPageProps } from 'types'

type TProps = {
  textClassName?: string
  size?: TResponsiveBlockSize
  noPadding?: boolean
}

const Breadcrumbs: FC<TProps> = ({ textClassName = 'text-text-black', size = 'wide', noPadding = false }) => {
  const {
    props: { breadcrumbs },
  } = usePage<ClientPageProps>()

  const length = breadcrumbs.length

  return (
    <ResponsiveBlock size={size} type="transparent" noPadding={noPadding}>
      <ul className="flex w-full flex-wrap gap-3 py-8">
        {breadcrumbs.map((item, index) => {
          const isLast = index === length - 1

          return (
            <li key={index} className="flex items-center gap-3">
              {isLast ? (
                <>
                  <Text tagName="span" className={cn('font-secondary-regular---12pt', textClassName)}>
                    {item.title}
                  </Text>
                </>
              ) : (
                <>
                  <Link
                    href={item?.url ?? '/'}
                    className={cn('transition-colors hover:text-text-tertiary hover:no-underline', textClassName)}
                  >
                    <Text tagName="span" className="font-secondary-regular---12pt hover:text-text-tertiary">
                      {item.title}
                    </Text>
                  </Link>
                  {!isLast && <span className={textClassName}>→</span>}
                </>
              )}
            </li>
          )
        })}
      </ul>
    </ResponsiveBlock>
  )
}

export { Breadcrumbs }
