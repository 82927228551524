import { CSSProperties, FC, PropsWithChildren } from 'react'
import ctl from '@netlify/classnames-template-literals'
import cn from 'classnames'

export type TResponsiveBlockSize = 'default' | 'wide' | 'content' | 'full' | 'medium'
type TResponsiveBlockType = 'primary' | 'secondary' | 'tertiary' | 'white' | 'transparent'

type TProps = {
  className?: string
  style?: CSSProperties
  size?: TResponsiveBlockSize
  type?: TResponsiveBlockType
  noPadding?: boolean
} & PropsWithChildren

const RESPONSIVE_STYLES: { [key in TResponsiveBlockSize]: string } = {
  default: ctl(`lg:w-10/12 xl:w-10/12 2xl:w-8/12`),
  wide: ctl(`lg:w-10/12 xl:w-10/12 2xl:w-9/12`),
  medium: ctl(`lg:w-10/12  2xl:w-8/12`),
  content: ctl(`lg:w-6/12`),
  full: ctl(`w-full`),
}

const BG_COLOR_CLASS: { [key in TResponsiveBlockType]: string } = {
  primary: 'bg-surface-primary',
  secondary: 'bg-surface-secondary',
  tertiary: 'bg-surface-tertiary',
  white: 'bg-surface-white',
  transparent: 'bg-transparent',
}

const ResponsiveBlock: FC<TProps> = ({
  className = '',
  style = {},
  size = 'default',
  type = 'white',
  noPadding = false,
  children,
}) => {
  return (
    <div className={cn('flex w-full justify-center', BG_COLOR_CLASS[type], className)} style={style}>
      <section
        className={cn(
          'flex w-full flex-col items-center',
          noPadding ? 'px-0' : 'px-6 lg:px-0',
          RESPONSIVE_STYLES[size],
        )}
      >
        {children}
      </section>
    </div>
  )
}

export { ResponsiveBlock }
