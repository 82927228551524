import { MutableRefObject, useEffect } from 'react'

function useClickOutside(refs: MutableRefObject<HTMLElement | null>[], callback: () => void) {
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const target = e.target as Node

      if (!refs.some((ref) => ref?.current?.contains(target))) {
        callback()
      }
    }

    document.addEventListener('click', handleClick, true)

    return () => {
      document.removeEventListener('click', handleClick, true)
    }
  }, [refs, callback])
}

export { useClickOutside }
