import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TelegramIcon, TelegramShareButton, TwitterShareButton, XIcon } from 'react-share'
import { Text } from '@blueprintjs/core'
import { usePage } from '@inertiajs/react'
import { DottedBlock, DotVariant } from 'client/components/DottedBlock/DottedBlock'
import { Breadcrumbs } from 'client/components/Layout/components/Breadcrumbs/Breadcrumbs'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { RATING_LS_KEY, TUserRatings, useRatingController } from 'client/controllers/ratingController'
import { format } from 'date-fns'
import { POST_DATE_FORMAT_STRING } from 'lib/constants/dates'
import { ClientLocaleNS } from 'lib/constants/locales'
import { getDataFromLS } from 'lib/utils/localStorage'
import lodash from 'lodash'

const { isNil } = lodash

import { getUserName } from 'lib/utils/getUserName'

import { Rating } from './components/Rating/Rating'
import { RatingDialog } from './components/RatingDialog/RatingDialog'

type TProps = {
  post: Data.Post
  share_url: string
}

const PostMainBlock: FC<{}> = () => {
  const {
    props: { post, share_url },
  } = usePage<TProps>()
  const { t: commonT } = useTranslation(ClientLocaleNS.Common)
  const { t } = useTranslation(ClientLocaleNS.PostPage)
  const { userRatings, setUserRatings } = useRatingController()

  useEffect(() => {
    if (!userRatings) {
      const ratings = getDataFromLS<TUserRatings>(RATING_LS_KEY)

      setUserRatings(isNil(ratings) ? {} : ratings)
    }
  }, [userRatings, setUserRatings])

  return (
    <ResponsiveBlock size="full" type="primary" noPadding>
      <DottedBlock variant={DotVariant.Secondary} className="relative flex-col pb-12 pt-4 max-md:pb-8">
        <Breadcrumbs size="full" textClassName="text-text-white" noPadding />
        <div className="flex w-full flex-col gap-12">
          <div className="relative z-1 flex w-full flex-col gap-8 text-text-white">
            <Text
              tagName="h1"
              className="font-secondary-regular---22pt whitespace-pre-wrap uppercase lg:font-secondary-regular---36pt"
            >
              {post.title}
            </Text>
            <Text tagName="span" className="font-secondary-regular---15pt whitespace-pre-wrap">
              {post.description}
            </Text>
          </div>
          <div className="flex w-full flex-col gap-8">
            <Text tagName="span" className="font-secondary-thin---15pt text-text-white">{`${
              post.reading_time || 0
            } ${commonT('captions.reading_time')} / ${t('main_block.last_updated')}: ${format(
              post.updated_at * 1000,
              POST_DATE_FORMAT_STRING,
            )}`}</Text>
            {post.author && (
              <div className="flex gap-5">
                <div className="flex h-16 w-16">
                  <img src={post.author.avatar_url} className="flex h-full w-full" />
                </div>
                <div className="flex flex-col justify-between text-text-white">
                  <Text tagName="span" className="font-primary-medium---12pt uppercase">
                    {getUserName(post.author)}
                  </Text>
                  <Text tagName="span" className="font-primary-regular---12pt">
                    {post.author.position}
                  </Text>
                </div>
              </div>
            )}
            <div className="flex items-center gap-20 text-text-tertiary max-sm:flex-col max-sm:items-start max-sm:gap-4">
              <div className="flex items-center gap-4">
                <Text tagName="span" className="font-primary-regular---15pt uppercase">{`${t(
                  'main_block.share',
                )}:`}</Text>
                <div className="flex items-center gap-2">
                  <TelegramShareButton className="outline-none" url={share_url}>
                    <TelegramIcon size={32} bgStyle={{ fill: 'none' }} iconFillColor="#0076ff" />
                  </TelegramShareButton>
                  <TwitterShareButton className="outline-none" url={share_url}>
                    <XIcon size={32} bgStyle={{ fill: 'none' }} iconFillColor="#0076ff" />
                  </TwitterShareButton>
                </div>
              </div>

              <Rating />
              <RatingDialog />
            </div>
          </div>
        </div>
      </DottedBlock>
    </ResponsiveBlock>
  )
}

export { PostMainBlock }
