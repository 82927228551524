import { FC, useRef, useState } from 'react'
import { Collapse, Text } from '@blueprintjs/core'
import cn from 'classnames'
import Chevron from 'images/chevron.png'
import { useClickOutside } from 'lib/hooks/useClickOutside'

type TProps = {
  data: Data.FaqItem
}

const FaqItem: FC<TProps> = ({ data }) => {
  const ref = useRef<HTMLLIElement>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useClickOutside([ref], () => {
    if (isOpen) {
      setIsOpen(false)
    }
  })

  return (
    <li ref={ref} className="flex w-full flex-col">
      <div
        className="flex w-full cursor-pointer items-center justify-between gap-8 py-8 lg:py-12"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Text
          tagName="span"
          className="font-primary-medium---18pt text-text-secondary max-lg:font-primary-medium---15pt"
        >
          {data.question}
        </Text>
        <div className="">
          <img src={Chevron} className={cn('w-6 object-contain transition-all', isOpen && 'rotate-180')} />
        </div>
      </div>
      <Collapse isOpen={isOpen}>
        <div className="flex w-full pb-8 pr-12 lg:w-8/12 lg:pb-12 lg:pr-0">
          <Text
            tagName="p"
            className="font-primary-regular---19pt w-full whitespace-pre-wrap text-text-secondary-alt max-lg:font-primary-regular---15pt"
          >
            {data.answer}
          </Text>
        </div>
      </Collapse>
    </li>
  )
}

export { FaqItem }
