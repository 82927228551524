export enum ClientLocaleNS {
  Common = 'common',
  MainPage = 'main_page',
  SonarPage = 'sonar_page',
  GatePage = 'gate_page',
  DigitalDistributionPage = 'digital_distribution',
  BuyerSupportPage = 'buyer_support',
  PartnerSupportPage = 'partner_support',
  PricingPage = 'pricing_page',
  PaymentMethodsPage = 'payment_methods',
  OurModelPage = 'our_model_page',
  MagazinePage = 'magazine_page',
  CareersPage = 'careers_page',
  DocsPage = 'docs_page',
  PricingOldPage = 'pricing_old_page',
  ErrorPage = 'error_page',
  WebsiteBuilderPage = 'website_builder_page',
  BlogPage = 'blog_page',
  PostPage = 'post_page',
  DemoPage = 'demo_page',
}
