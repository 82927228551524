import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { usePage } from '@inertiajs/react'
import ctl from '@netlify/classnames-template-literals'
import cn from 'classnames'
import { MAX_STARS, useRatingController } from 'client/controllers/ratingController'
import Star from 'images/svg/star_rating.svg?react'
import { ClientLocaleNS } from 'lib/constants/locales'
import lodash from 'lodash'

const { isNil } = lodash

type TProps = {
  post: Data.Post
}

const getFilledClassName = (isFilled: boolean) => (isFilled ? ctl(`text-text-tertiary`) : ctl(`text-text-secondary`))

const Rating: FC<{}> = () => {
  const {
    props: { post },
  } = usePage<TProps>()
  const { t } = useTranslation(ClientLocaleNS.PostPage)
  const { isOpen, setIsOpen, userRatings } = useRatingController()

  const votedValue = userRatings ? userRatings[post.id] : undefined

  return (
    <div className="flex items-center gap-4">
      <Text tagName="span" className="font-primary-regular---15pt uppercase">{`${t('main_block.rate')}:`}</Text>
      <ul
        className={cn('flex items-center gap-1', isNil(votedValue) && 'cursor-pointer')}
        onClick={() => {
          if (isNil(votedValue) && !isOpen) {
            setIsOpen(true)
          }
        }}
      >
        {Array(MAX_STARS)
          .fill(null)
          .map((_, index) => {
            const isFilled = index + 1 <= post.rating

            return (
              <li key={index} className={cn('flex w-5', getFilledClassName(isFilled))} onMouseEnter={() => {}}>
                <Star />
              </li>
            )
          })}
      </ul>
    </div>
  )
}

export { Rating }
