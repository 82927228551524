import { FC } from 'react'
import { usePage } from '@inertiajs/react'
import { Decoration } from 'client/components/Decoration/Decoration'

import { ContentBlock } from './components/ContentBlock/ContentBlock'

type TProps = {
  post: Data.Post
}

const BlocksBlock: FC<{}> = () => {
  const {
    props: {
      post: { post_blocks },
    },
  } = usePage<TProps>()

  return (
    <div className="flex w-full py-12 lg:py-20">
      <div className="relative flex w-full flex-col">
        <div className="flex w-full flex-col gap-8">
          {post_blocks.map((item, index) => {
            return <ContentBlock key={item.id} block={item} index={index} />
          })}
        </div>
        <Decoration />
      </div>
    </div>
  )
}

export { BlocksBlock }
