import { FC, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'
import { Spinner, SpinnerSize, Text } from '@blueprintjs/core'
import { usePage } from '@inertiajs/react'
import ctl from '@netlify/classnames-template-literals'
import axios from 'axios'
import cn from 'classnames'
import { MAX_STARS, useRatingController } from 'client/controllers/ratingController'
import { motion, Variants } from 'framer-motion'
import Star from 'images/svg/star_rating.svg?react'
import { ClientLocaleNS } from 'lib/constants/locales'
import lodash from 'lodash'

const { isNil } = lodash

type TProps = {
  post: Data.Post
}

type TSubmitResult = {
  text: string
  status: 'success' | 'failure'
} | null

const variants: Variants = {
  hidden: {
    height: 0,
    opacity: 0,
    overflow: 'hidden',
  },
  visible: {
    height: 'auto',
    opacity: 1,
    overflow: 'visible',
  },
}

const getFilledClassName = (isFilled: boolean) => (isFilled ? ctl(`text-text-tertiary`) : ctl(`text-text-secondary`))

const RatingSelector: FC<{}> = () => {
  const {
    props: { post },
  } = usePage<TProps>()
  const { t } = useTranslation(ClientLocaleNS.PostPage)
  const { setIsOpen, addUserRating } = useRatingController()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [submitResult, setSubmitResult] = useState<TSubmitResult>(null)

  const submitRating = (value: number) => {
    if (!executeRecaptcha) {
      return
    }

    executeRecaptcha('rating_submit').then((gReCaptchaToken) => {
      setLoading(true)
      axios
        .post('/api/v1/ratings', { rating: { post_id: post.id, value }, gc_token: gReCaptchaToken })
        .then(() => {
          setSubmitResult({ text: t('rating.success'), status: 'success' })
          addUserRating(post.id, value)
        })
        .catch(({ response }) => {
          const errors: { [key: string]: string } = response?.data?.errors

          setSubmitResult({ text: t('rating.failed'), status: 'failure' })
          console.error(errors)
        })
        .finally(() => {
          setLoading(false)
          setTimeout(() => setIsOpen(false), 1500)
        })
    })
  }

  return (
    <div className="flex flex-col items-center gap-8 p-12">
      <Text tagName="span" className="font-primary-medium---22pt text-text-white">{`${t('rating.title')}:`}</Text>
      <ul
        className={cn('flex items-center gap-4', !isNil(hoveredIndex) && 'cursor-pointer')}
        onMouseLeave={() => {
          setHoveredIndex(null)
        }}
      >
        {Array(MAX_STARS)
          .fill(null)
          .map((_, index) => {
            const isFilled = isNil(hoveredIndex) ? false : index <= hoveredIndex

            return (
              <li
                key={index}
                className={cn('flex h-12 w-12', getFilledClassName(isFilled))}
                onMouseEnter={() => {
                  setHoveredIndex(index)
                }}
                onClick={() => {
                  submitRating(index + 1)
                }}
              >
                <Star className="h-full w-full object-contain" />
              </li>
            )
          })}
      </ul>
      <motion.div
        className=""
        variants={variants}
        animate={submitResult || loading ? 'visible' : 'hidden'}
        initial="hidden"
        exit="hidden"
        transition={{ duration: 0.2, bounceDamping: false }}
      >
        {loading ? (
          <Spinner size={SpinnerSize.STANDARD} />
        ) : (
          submitResult && (
            <Text
              tagName="span"
              className={cn('font-primary-regular---15pt flex transition-opacity', {
                'text-functional-success': submitResult?.status === 'success',
                'text-functional-error': submitResult?.status === 'failure',
              })}
            >
              {submitResult?.text ?? ''}
            </Text>
          )
        )}
      </motion.div>
    </div>
  )
}

export { RatingSelector }
