import { FC, PropsWithChildren } from 'react'
import ctl from '@netlify/classnames-template-literals'
import cn from 'classnames'
import imageDots from 'images/dots.png'
import dotsagain from 'images/dotsagain.png'

type TProps = {
  className?: string
  variant?: DotVariant
} & PropsWithChildren

export enum DotVariant {
  Primary = 'primary',
  Secondary = 'secondary',
}

const BG_IMAGES: {
  [key in DotVariant]: {
    className: string
    image: string
  }
} = {
  [DotVariant.Primary]: {
    className: ctl(`bg-repeat`),
    image: imageDots,
  },
  [DotVariant.Secondary]: {
    className: ctl(`bg-cover bg-center bg-no-repeat`),
    image: dotsagain,
  },
}

const DottedBlock: FC<TProps> = ({ className, children, variant = DotVariant.Primary }) => {
  return (
    <div
      className={cn('flex w-full', BG_IMAGES[variant].className, className)}
      style={{ backgroundImage: `url(${BG_IMAGES[variant].image})` }}
    >
      {children}
    </div>
  )
}

export { DottedBlock }
