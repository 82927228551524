import { create } from 'zustand'

interface IClaimState {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const useClaimController = create<IClaimState>()((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) => set(() => ({ isOpen })),
}))

export { useClaimController }
