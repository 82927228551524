import { FC } from 'react'
import { AnimatedButton } from 'client/components/AnimatedButton/AnimatedButton'
import { AnimatedLink } from 'client/components/AnimatedLink/AnimatedLink'
import { useClaimController } from 'client/controllers/claimController'

type TProps = {
  block: Data.PostBlock<'cta'>
}

const CtaButton: FC<TProps> = ({ block }) => {
  const { setIsOpen } = useClaimController()

  switch (block.content.action_type) {
    case 'claim':
      return <AnimatedButton onClick={() => setIsOpen(true)}>{block.content.button_text}</AnimatedButton>
    case 'link':
      return (
        <AnimatedLink href={block.content.url} target="_blank" rel="noopener noreferrer">
          {block.content.button_text}
        </AnimatedLink>
      )
    default:
      return null
  }
}

export { CtaButton }
