import { FC, useEffect, useRef } from 'react'
import ctl from '@netlify/classnames-template-literals'

type TProps = {
  block: Data.PostBlock<'text'>
  index: number
}

const TextBlock: FC<TProps> = ({ block, index }) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      const headings = ref.current.querySelectorAll('h2,h3,h4')

      headings.forEach((item, hIndex) => {
        item.setAttribute('id', `${index}_${hIndex}`)
      })
    }
  }, [ref, index])

  return (
    <div
      ref={ref}
      className={ctl(`
        font-primary-regular---15pt flex max-h-full w-full flex-col overflow-x-auto
        text-text-white lg:leading-10 [&_table]:border-collapse [&_td]:border
        [&_td]:border-solid [&_td]:border-text-white [&_td]:p-4
      `)}
      dangerouslySetInnerHTML={{ __html: block?.content?.text ?? '' }}
    />
  )
}

export { TextBlock }
