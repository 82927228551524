import { FC } from 'react'
import { Text } from '@blueprintjs/core'
import ctl from '@netlify/classnames-template-literals'
import { Card, CardType, OutlineType } from 'client/components/Card/Card'

import { CtaButton } from './components/CtaButton/CtaButton'

type TProps = {
  block: Data.PostBlock<'cta'>
}

const CtaBlock: FC<TProps> = ({ block }) => {
  return (
    <div className="flex w-full flex-col gap-8 lg:gap-20">
      <Card type={CardType.Black} outlineType={OutlineType.Border} className="gap-8 p-8 md:px-20 md:py-30">
        <div className="flex w-full flex-col items-center justify-between gap-8 md:flex-row lg:flex-col xl:flex-row">
          <div className="flex w-full justify-center md:justify-start lg:justify-center xl:w-8/12 xl:justify-start">
            <div className="flex flex-col flex-wrap items-center gap-3">
              <Text
                tagName="span"
                className={ctl(
                  `font-secondary-regular---22pt whitespace-pre-wrap
                  text-center text-text-white lg:font-secondary-regular---36pt
                  md:text-left lg:text-center xl:text-start`,
                )}
              >
                {block.content.text}
              </Text>
            </div>
          </div>
          <div className="flex max-md:w-full max-md:justify-center">
            <CtaButton block={block} />
          </div>
        </div>
      </Card>
    </div>
  )
}

export { CtaBlock }
