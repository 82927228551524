import { FC } from 'react'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'

import { BlocksBlock } from './components/BlocksBlock/BlocksBlock'
import { CtaBlock } from './components/CtaBlock/CtaBlock'
import { FaqBlock } from './components/FaqBlock/FaqBlock'
import { ListOfContents } from './components/ListOfContents/ListOfContents'
import { NextPosts } from './components/NextPosts/NextPosts'
import { PostMainBlock } from './components/PostMainBlock/PostMainBlock'

const PostContent: FC<{}> = ({}) => {
  return (
    <div className="relative z-0 flex w-full flex-col">
      <ResponsiveBlock size="wide" type="primary">
        <PostMainBlock />
        <div className="flex w-full gap-20 max-lg:flex-col max-lg:gap-8">
          <div className="flex flex-1 flex-col gap-8 max-lg:w-full">
            <ListOfContents />
            <BlocksBlock />
          </div>
          <div className="flex w-3/12 flex-col pt-20 max-lg:w-full max-md:pt-0">
            <NextPosts />
          </div>
        </div>
      </ResponsiveBlock>
      <CtaBlock />
      <FaqBlock />
    </div>
  )
}

export { PostContent }
