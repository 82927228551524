import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { usePage } from '@inertiajs/react'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { ClientLocaleNS } from 'lib/constants/locales'

import { FaqItem } from './components/FaqItem/FaqItem'

type TProps = {
  post: Data.Post
}

const FaqBlock: FC<{}> = () => {
  const { t } = useTranslation(ClientLocaleNS.PostPage)
  const {
    props: {
      post: { faq },
    },
  } = usePage<TProps>()

  if (!faq) return null

  return (
    <ResponsiveBlock size="wide" type="primary">
      <div className="flex w-full flex-col justify-center gap-8 pt-12 lg:gap-20 lg:px-12 lg:pt-30">
        <Text
          tagName="span"
          className="font-secondary-regular---36pt text-center uppercase text-text-white max-lg:font-secondary-regular---22pt"
        >
          {t('faq_block.title')}
        </Text>
        <ul className="flex w-full flex-col">
          {faq.faq_items.map((item) => {
            return <FaqItem key={item.id} data={item} />
          })}
        </ul>
      </div>
    </ResponsiveBlock>
  )
}

export { FaqBlock }
