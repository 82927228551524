import { FC } from 'react'
import { Text } from '@blueprintjs/core'

type TProps = {
  block: Data.PostBlock<'image'>
}

const ImageBlock: FC<TProps> = ({ block }) => {
  return (
    <div className="flex w-full flex-col gap-4">
      <img src={block.content.image_url} className="w-full object-contain" />
      <Text
        tagName="span"
        className="font-primary-regular---15pt italic text-text-secondary"
        dangerouslySetInnerHTML={{ __html: block?.content?.caption ?? '' }}
      />
    </div>
  )
}

export { ImageBlock }
