import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { usePage } from '@inertiajs/react'
import { Card, CardType } from 'client/components/Card/Card'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {
  post: Data.Post
  share_url: string
  list_of_contents: {
    text: string
    href: string
  }[]
}

const ListOfContents: FC<{}> = () => {
  const { t } = useTranslation(ClientLocaleNS.PostPage)
  const {
    props: { post, list_of_contents },
  } = usePage<TProps>()

  if (!post?.list_of_contents_enabled) return null

  return (
    <div className="flex">
      <Card type={CardType.Black} noBg>
        <div className="flex flex-col gap-8">
          <Text tagName="span" className="font-primary-medium---18pt text-text-white">
            {t('list_of_contents.title')}
          </Text>
          <ul className="flex flex-col gap-4">
            {list_of_contents.map((item, index) => {
              return (
                <li key={index} className="font-primary-regular---15pt">
                  <a href={`#${item.href}`}>{item.text}</a>
                </li>
              )
            })}
          </ul>
        </div>
      </Card>
    </div>
  )
}

export { ListOfContents }
