import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import { ClientLocaleNS } from 'lib/constants/locales'

import blogPageJSON from './en/blog_page.json'
import buyerSupportJSON from './en/buyer_support_page.json'
import careersPageJSON from './en/careers_page.json'
import commonJSON from './en/common.json'
import demoPageJSON from './en/demo_page.json'
import digitalDistributionJSON from './en/digital_distribution.json'
import docsPageJSON from './en/docs_page.json'
import errorPageJSON from './en/error_page.json'
import gatePageJSON from './en/gate_page.json'
import magazinePageJSON from './en/magazine_page.json'
import mainPageJSON from './en/main_page.json'
import ourModelJSON from './en/our_model_page.json'
import partnerSupportJSON from './en/partner_support_page.json'
import paymentMethodsPageJSON from './en/payment_methods.json'
import postPageJSON from './en/post_page.json'
import pricingOldJSON from './en/pricing_old_page.json'
import pricingJSON from './en/pricing_page.json'
import sonarPageJSON from './en/sonar_page.json'
import websiteBuilderPageJSON from './en/website_builder_page.json'

i18n.use(initReactI18next).init({
  resources: {
    en: {
      [ClientLocaleNS.Common]: commonJSON,
      [ClientLocaleNS.MainPage]: mainPageJSON,
      [ClientLocaleNS.SonarPage]: sonarPageJSON,
      [ClientLocaleNS.GatePage]: gatePageJSON,
      [ClientLocaleNS.DigitalDistributionPage]: digitalDistributionJSON,
      [ClientLocaleNS.BuyerSupportPage]: buyerSupportJSON,
      [ClientLocaleNS.PartnerSupportPage]: partnerSupportJSON,
      [ClientLocaleNS.PricingPage]: pricingJSON,
      [ClientLocaleNS.PaymentMethodsPage]: paymentMethodsPageJSON,
      [ClientLocaleNS.OurModelPage]: ourModelJSON,
      [ClientLocaleNS.MagazinePage]: magazinePageJSON,
      [ClientLocaleNS.CareersPage]: careersPageJSON,
      [ClientLocaleNS.DocsPage]: docsPageJSON,
      [ClientLocaleNS.PricingOldPage]: pricingOldJSON,
      [ClientLocaleNS.ErrorPage]: errorPageJSON,
      [ClientLocaleNS.WebsiteBuilderPage]: websiteBuilderPageJSON,
      [ClientLocaleNS.BlogPage]: blogPageJSON,
      [ClientLocaleNS.PostPage]: postPageJSON,
      [ClientLocaleNS.DemoPage]: demoPageJSON,
    },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
