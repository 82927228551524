export const isTextBlock = (data: Data.PostBlock<any>): data is Data.PostBlock<'text'> => {
  return data.block_type === 'text'
}

export const isImageBlock = (data: Data.PostBlock<any>): data is Data.PostBlock<'image'> => {
  return data.block_type === 'image'
}

export const isCtaBlock = (data: Data.PostBlock<any>): data is Data.PostBlock<'cta'> => {
  return data.block_type === 'cta'
}
